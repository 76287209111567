import { Component, input, output } from '@angular/core';
import { ConcertEvent } from '../../../../core/models/types.model';
import { FormatService } from '../../../../core/services/format.service';

@Component({
  selector: 'app-see-more-event',
  standalone: true,
  imports: [],
  templateUrl: './see-more-event.component.html',
  styleUrl: './see-more-event.component.scss'
})
export class SeeMoreEventComponent {
  // Input
  event = input.required<ConcertEvent>();

  // Output
  onEventClick = output<ConcertEvent>();

  constructor (private formatService: FormatService) {
  }

  eventClick () {
    this.onEventClick.emit(this.event());
  }

  getFormatDate (date : string | undefined) {
    return this.formatService.formatEventDate(date);
  }

  getEventTitle (): string {
    return this.formatService.formatEventTitle(this.event());
  }
}
