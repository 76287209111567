import { Component, OnInit, input, output, signal } from '@angular/core';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-time-filter',
  standalone: true,
  imports: [NgxMaterialTimepickerModule, MatIconModule],
  templateUrl: './time-filter.component.html',
  styleUrl: './time-filter.component.scss'
})
export class TimeFilterComponent implements OnInit {
  // Inputs
  initialValue = input<string[]>(['06:00', '02:00']);

  // Outputs
  changeTimeFilterOutput = output<string[]>();

  // Local Variables
  startTime = signal('06:00');
  endTime = signal('02:00');

  ngOnInit (): void {
    if (this.initialValue().length === 2) {
      this.startTime.set(this.initialValue()[0]);
      this.endTime.set(this.initialValue()[1]);
    }
  }

  onStartTimeChange (time: string): void {
    this.startTime.set(time);
    if (this.getTimeValue(this.endTime()) < this.getTimeValue(this.startTime())) {
      this.endTime.set(this.startTime());
    }
    this.emitChanges();
  }

  onEndTimeChange (time: string): void {
    this.endTime.set(time);
    if (this.getTimeValue(this.startTime()) > this.getTimeValue(this.endTime())) {
      this.startTime.set(this.endTime());
    }
    this.emitChanges();
  }

  resetAllTimes (): void {
    this.startTime.set('06:00');
    this.endTime.set('02:00');
    this.emitChanges();
  }

  private emitChanges (): void {
    this.changeTimeFilterOutput.emit([this.startTime(), this.endTime()]);
  }

  getTimeValue (time: string): number {
    const [hours, minutes] = time.split(':').map(Number);
    
    if (hours < 6) {
      return 24 * 60 + hours * 60 + minutes;
    }
    return hours * 60 + minutes;
  }
}

