import { Component, input} from '@angular/core';
import { type ConcertEvent } from '../../../../core/models/types.model';
import { FormatService } from '../../../../core/services/format.service';

@Component({
  selector: 'app-event-chip',
  standalone: true,
  imports: [],
  templateUrl: './event-chip.component.html',
  styleUrl: './event-chip.component.scss'
})
export class EventChipComponent {
  // Inputs
  event = input.required<ConcertEvent>();

  constructor (private formatService: FormatService) {
  }

  getEventTitle (): string {
    return this.formatService.formatEventTitle(this.event());
  }
}
