import { Component, input } from '@angular/core';
import { type ConcertEvent } from '../../../core/models/types.model';
import { EventChipComponent } from "./event-chip/event-chip.component";
import { AppState } from '../../../core/states/app.state';
import { launchExtendedInfo } from '../../../core/states/day/day.actions';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-day-container',
  standalone: true,
  imports: [EventChipComponent],
  templateUrl: './day-container.component.html',
  styleUrl: './day-container.component.scss'
})

export class DayContainerComponent {
  // Inputs
  date = input.required<Date>();
  statusDay = input.required<string>();
  events = input.required<ConcertEvent[]>();
  isOffMonth = input<boolean>();

  constructor (public store : Store<AppState>) {
  }

  isWeekend (): boolean {
    const weekDay = this.date().getDay(); // Sunday 0 -> Satuday 6
    return weekDay >= 6 || weekDay === 0;
  }

  onDayClick () : void {
    const thisDay = {
      date: this.date(),
      events: this.events(),
      isOffMonth: this.isOffMonth(),      
    };

    this.store.dispatch(launchExtendedInfo({
      day: thisDay,
    }));
  }

  getValidEvents (): ConcertEvent[] {
    return this.events()
      .filter((event) => event !== null && event !== undefined)
      .slice(0, 5); // Limita el array a un máximo de 5 elementos
  }
  
}


