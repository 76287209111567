<div class="ext-info-layout" [class.open]="isOpen()">

  <div class="overlay" 
    (click)="hideExtendedInfo()"
    (keydown)="hideExtendedInfo()"
    aria-disabled="true">
  </div>
  
  <div class="drawer">

    <div class="extended-info">

      <button (click)="hideExtendedInfo()" class="close-btn">
        <img class="icon" src="/assets/close_arrow.svg" alt="close extended-info">
      </button>
    
      <button (click)="openExtendedInfo()" class="open-btn">
        <img class="icon" src="/assets/open_arrow.svg" alt="show extended-info">
      </button>
      
      @if (isOpen()) {
        @if (selectedDay()) {

          <div class="header">
            <div class="day-row">
              <button class="main-button" (click)="previousDay()">
                <img src="/assets/left_arrow.svg" alt="previous month">
              </button>
              <h2>
                {{ selectedDay().date | date:'EEEE d' }}<br>
                {{ selectedDay().date | date:'MMMM y' }}
              </h2>
              <button class="main-button" (click)="nextDay()">
                <img src="/assets/right_arrow.svg" alt="next month">
              </button>
            </div>
          </div>

          <button mat-icon-button class="close-modal-btn" (click)="hideExtendedInfo()">
            <mat-icon>close</mat-icon>
          </button>

          <app-order-dropdown class="order-dropdown" (orderChangeOutput)="onOrderChange($event)"></app-order-dropdown>

          <div class="events">
            @if (shownEvents().length > 0) {
              @for (event of shownEvents(); track event.id) {
                <app-event-info (onEventClick)="onEventInfoClick($event)" [event]="event"></app-event-info>
              }
              @if (shownEvents().length > 2) {
                <img class="icon" src="/assets/see_more_icon.svg" alt="see more events">
              }
            } @else {
              <div class="no-info-message">
                <p>No tenemos eventos guardados para este día ☹</p>
                <p>¿Nos ayudas añadiendo más conciertos?</p>
              </div>
            }
          </div>

        } @else {
          <p class="no-info-message">Selecciona un día para ver todos los eventos detallados</p>
        }
      }
    </div>
  </div>
</div>