import { Component, input, output } from '@angular/core';
import { type ConcertEvent } from '../../../core/models/types.model';

@Component({
  selector: 'app-event-info',
  standalone: true,
  imports: [],
  templateUrl: './event-info.component.html',
  styleUrl: './event-info.component.scss'
})
export class EventInfoComponent {
  // Inputs
  event = input.required<ConcertEvent>();

  // Outputs
  onEventClick = output<ConcertEvent>();

  eventClick () {
    this.onEventClick.emit(this.event());
  }

  getEventName (): string {
    return this.event().festival || this.event().bands.join(' + ');
  }

  getFormatPrice (price : number) : string {
    return Math.round(price) + ' €';
  }

  getEventGenres (): { id: number; name: string }[] {
    const event = this.event();
    
    if (!event || !Array.isArray(event.genres)) {
      return []; // Retorna un array vacío si no hay géneros válidos
    }
  
    const abbreviations: Record<string, string> = {
      Flamenco: 'Flam.',
      Electronica: 'Elec.',
      Clasica: 'Clas.',
      Latina: 'Lat.',
      // Agrega más abreviaciones según sea necesario
    };
  
    return event.genres
      .map((genre, index) => ({
        id: index + 1,
        name: abbreviations[genre] || genre,
      }))
  }

  getGenreClass (genre: { name: string }): string {
    switch (genre.name.toLowerCase()) {
    case 'rock': return 'rock';
    case 'pop': return 'pop';
    case 'flam.': return 'flamenco';
    case 'indie': return 'indie';
    case 'punk': return 'punk';
    case 'clas.': return 'clasica';
    case 'rap': return 'rap';
    case 'jazz': return 'jazz';
    case 'elec.': return 'electronica';
    case 'metal': return 'metal';
    case 'folk': return 'folk';
    case 'lat.': return 'latina';
    case 'otros': return 'otros';
    default: return 'otros';
    }
  }
  
}
