import {Component, Inject, OnDestroy, signal} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogTitle,
  MatDialogContent,
  MatDialogClose,
  MatDialogRef,
  
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

import { ConcertEvent } from '../../../core/models/types.model';
import { EventsService } from '../../../core/services/events.service';
import { Subscription, forkJoin } from 'rxjs';
import { SeeMoreEventComponent } from "./see-more-event/see-more-event.component";
import { DatePipe } from '@angular/common';
import { FormatService } from '../../../core/services/format.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-event-details-modal',
  standalone: true,
  imports: [MatDialogTitle, MatDialogContent, MatIconModule, MatButtonModule, MatDialogClose, SeeMoreEventComponent, DatePipe],
  templateUrl: './event-details-modal.component.html',
  styleUrl: './event-details-modal.component.scss'
})

export class EventDetailsModalComponent implements OnDestroy {
  // Local Variables
  loadingMore = signal(false);
  showingMoreEvents = signal(false);
  allArtistsEvents = signal<ConcertEvent[]>([]);
  moreEvents = signal<ConcertEvent[]>([]);

  // Observables
  moreEventsRequest?: Subscription;

  constructor (@Inject(MAT_DIALOG_DATA) public data: { event: ConcertEvent, date: string },
    private eventsService: EventsService,
    private formatService: FormatService,
    private router: Router,
    public dialogRef: MatDialogRef<EventDetailsModalComponent>) {
  }

  showMoreEvents () {
    this.loadingMore.set(true);
    this.showingMoreEvents.set(true);
  
    if (this.data?.event) {
      this.requestMoreEvents();
    }
  }

  requestMoreEvents () {
    if (this.moreEventsRequest) {
      this.moreEventsRequest.unsubscribe();
    }
  
    const artists = this.data.event.bands;
    const todayDate = new Date();

    // Crear un array de observables, uno por cada artista
    const requests = artists.map((artist) => 
      this.eventsService.getEventsFromArtist(artist, todayDate)
    );

    // Ejecutar todas las peticiones en paralelo
    this.moreEventsRequest = forkJoin(requests).subscribe({
      next: (responses) => {
        this.loadingMore.set(false);
    
        // Combinar todos los eventos de las respuestas
        const allEvents = responses.map(response => Object.values(response.events).flat());
    
        // Eliminar eventos duplicados basado en el id
        const uniqueEvents = allEvents.flat().filter((event, index, self) =>
          index === self.findIndex(e => e.id === event.id)
        );
    
        this.allArtistsEvents.set(uniqueEvents);
    
        this.filterMoreEvents();
      },
      error: (error) => {
        this.loadingMore.set(false);
        console.error('Error handled in subscribe:', error.message);
      }
    });
  }

  hideMoreEvents () {
    if (this.moreEventsRequest) {
      this.moreEventsRequest.unsubscribe();
    }
    this.showingMoreEvents.set(false);
    this.loadingMore.set(false);
  }

  filterMoreEvents () {
    this.moreEvents.set(
      this.allArtistsEvents()
        .filter((event) => {
          // Check if event and date exist
          return event?.date && event.date !== this.data.date;
        })
        .sort((a, b) => {
          // Ensure dates are defined and create Date objects for comparison
          const dateA = a.date ? new Date(a.date).getTime() : 0;
          const dateB = b.date ? new Date(b.date).getTime() : 0;
          return dateA - dateB;
        })
    );
  }

  getEventName (): string {
    if (this.data?.event) {
      return this.formatService.formatEventTitle(this.data.event);
    } else {
      return '';
    }
  }

  changeEventModal (selectedEvent : ConcertEvent): void {
    this.data.event = selectedEvent;
    this.data.date = selectedEvent.date as string;
    this.requestMoreEvents()
    this.filterMoreEvents();
  }

  ngOnDestroy (): void {
    this.router.navigate(['']); // Cambia la URL antes de cerrar
    if (this.moreEventsRequest) {
      this.moreEventsRequest.unsubscribe();
    }
  }
}
