<div class="event-card" (click)="eventClick()" role="button" (keyup)="eventClick()" aria-disabled="true"
  [class.festival]="event().festival">
  <div class="expand-icon">
    <img class="icon" src="/assets/expand_icon.svg" alt="expand event info">
  </div>
  @if (event().festival) {
    <div class="festival-icon">
      festival
    </div>
  }
  <div class="event-header">
    <h2 class="event-title">{{ getEventName()}}</h2>
  </div>



  <div class="event-body">

    <div class="location-row">
      <p id="event-info-city" class="bold">{{ event().city }}</p>
      <p id="event-info-location" class="ellipsis">{{ event().location }}</p>
    </div>

    <div class="details-row">
      <div class="details">
        <p id="event-info-time">{{ event().time || '--:--' }}</p>
        <strong>·</strong>
        @if (event().price !== null && event().price !== undefined) {
          <p id="event-info-price">{{ event().price === 0 ? 'Gratis' : (getFormatPrice(event().price))}}</p>
        } @else {
          <p> - </p>
        }
      </div>


      <div class="genres-container">
        @for (genre of getEventGenres(); track genre.id) {
          <div class="genre-chip" [class]="getGenreClass(genre)">
            {{genre.name}}
          </div>
        }
      </div>
    </div>

    @if (event().festival) {
      <div class="bands-list">
        <strong>Artistas:</strong> {{ event().bands.join('    ') }}
      </div>
    }
  </div>
</div>
