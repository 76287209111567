<!-- src/app/month-navigation/month-navigation.component.html -->
<div class="month-navigation">
  <button class="main-button" (click)="previousMonth()">
    <img class="icon" src="/assets/left_arrow.svg" alt="previous month">
  </button>
  <button class="main-button" (click)="nextMonth()">
    <img class="icon" src="/assets/right_arrow.svg" alt="next month">
  </button>
  <span class="month-name" (click)="toggleMonthDropdown()" (keyup)="toggleMonthDropdown()" aria-disabled="true">
    {{ monthsName[currentDate().month].name }}&nbsp;&nbsp;&nbsp;{{ currentDate().year }}
  </span>
  @if (currentDate().month !== todayDate.month || currentDate().year !== todayDate.year) {
    <button class="today-button" (click)="goToToday()">
      <mat-icon>history</mat-icon>
      <span>HOY</span>
    </button>
  }

  @if (showMonthDropdown()) {
    <div class="dropdown">
      <div class="top-row">
        <div class="year-selector">
          <button (click)="decrementYear()">&#9660;</button>
          <span>{{ currentDate().year }}</span>
          <button (click)="incrementYear()">&#9650;</button>
        </div>
        <button mat-icon-button class="close-btn" (click)="closeDropdown()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <div class="month-selector">
        @for (month of monthsName; track month.ordinal) {
          <div class="month-option" (keyup)="selectMonth(month.ordinal)" aria-disabled="true" (click)="selectMonth(month.ordinal)">
            {{ month.abr }}
          </div>
        }
      </div>
    </div>
  }
</div>
  