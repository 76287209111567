import { Component, input, output, signal, OnInit } from '@angular/core';
import { type Toggles } from '../../../core/models/filters.model';

@Component({
  selector: 'app-toggles',
  standalone: true,
  imports: [],
  templateUrl: './toggles.component.html',
  styleUrl: './toggles.component.scss'
})
export class TogglesComponent implements OnInit {
  // Inputs
  initialValue = input<Toggles>({
    onlyFest: false,
    onlyFree: false,
    hideFest: false,
  });

  // Outputs
  changeToggleOutput = output<Toggles>();

  // Local Variables
  toggles = signal<Toggles>({
    onlyFest: false,
    onlyFree: false,
    hideFest: false,
  });

  ngOnInit (): void {
    this.toggles.set(this.initialValue());
  }

  onTogglesChange (event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const toggleKey = inputElement.name as keyof Toggles;

    const currentToggles = this.toggles();

    if (toggleKey === 'onlyFest' && inputElement.checked) {
      currentToggles.hideFest = false;
    } else if (toggleKey === 'hideFest' && inputElement.checked) {
      currentToggles.onlyFest = false;
    }

    this.toggles.set({
      ...currentToggles,
      [toggleKey]: inputElement.checked
    });

    this.changeToggleOutput.emit(this.toggles());
  }
}
