import {COMMA} from '@angular/cdk/keycodes';
import {ChangeDetectionStrategy, Component, model, output, input, computed} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatChipsModule} from '@angular/material/chips';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';

@Component({
  selector: 'app-genre-filter',
  templateUrl: './genre-filter.component.html',
  styleUrl: './genre-filter.component.scss',
  standalone: true,
  imports: [MatFormFieldModule, MatChipsModule, MatIconModule, MatAutocompleteModule, FormsModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class GenreFilterComponent {
  // Inputs
  initialValue = input<string[]>(['Todos']);
 
  // Outputs
  changeGenreFilter = output<string[]>();

  // Materials Variables
  readonly separatorKeysCodes: number[] = [COMMA];
  readonly currentGenre = model('');

  allGenres = computed(() => 
    (this.initialValue().length ? this.getGenresValue(this.initialValue()) : [
      { id: 1, name: 'Rock', active: false },
      { id: 2, name: 'Pop', active: false },
      { id: 3, name: 'Flamenco', active: false },
      { id: 4, name: 'Indie', active: false },
      { id: 5, name: 'Punk', active: false },
      { id: 6, name: 'Clasica', active: false },
      { id: 7, name: 'Rap', active: false },
      { id: 8, name: 'Jazz', active: false },
      { id: 9, name: 'Electronica', active: false },
      { id: 10, name: 'Metal', active: false },
      { id: 11, name: 'Folk', active: false },
      { id: 12, name: 'Latina', active: false },
      { id: 13, name: 'Otros', active: false }
    ])
  );

  getGenresValue (activeGenres: string[]) {
    const genres: { id: number, name: string, active: boolean }[] = [
      { id: 1, name: 'Rock', active: false },
      { id: 2, name: 'Pop', active: false },
      { id: 3, name: 'Flamenco', active: false },
      { id: 4, name: 'Indie', active: false },
      { id: 5, name: 'Punk', active: false },
      { id: 6, name: 'Clasica', active: false },
      { id: 7, name: 'Rap', active: false },
      { id: 8, name: 'Jazz', active: false },
      { id: 9, name: 'Electronica', active: false },
      { id: 10, name: 'Metal', active: false },
      { id: 11, name: 'Folk', active: false },
      { id: 12, name: 'Latina', active: false },
      { id: 13, name: 'Otros', active: false }
    ];
    genres.forEach(genre => {
      if (activeGenres.includes(genre.name)) {
        genre.active = true;
      }
    });
    return genres;
  }
  
  selectAll () {
    this.allGenres().forEach((genre) => {
      genre.active = true;
    });
    this.emitActiveGenres();
  }
  
  deselectAll () {
    this.allGenres().forEach((genre) => {
      genre.active = false;
    });
    this.emitActiveGenres();
  }
  
  toggleGenre (genre: { id: number, name: string, active: boolean }) {
    genre.active = !genre.active;
    this.emitActiveGenres();
  }
  
  emitActiveGenres () {
    const activeGenres = this.allGenres().filter((genre) => {
      return genre.active;
    }).map((genre) => genre.name);
    this.changeGenreFilter.emit(activeGenres);
  }
}
