<div class="time-layout">

  @if (startTime() !== '06:00' || endTime() !== '02:00') {
    <button mat-icon-button (click)="resetAllTimes()">
      <mat-icon>refresh</mat-icon>
    </button>
  }
  <div class="time-filter">
    <div class="time-picker-group">
      <p class="time-label">Desde</p>
      <div class="time-input">
        <mat-icon class="time-icon">schedule</mat-icon>
        <input [ngxTimepicker]="pickerStart" 
          readonly 
          [format]="24" 
          [value]="startTime()">
        <ngx-material-timepicker #pickerStart
          (timeSet)="onStartTimeChange($event)"
          [minutesGap]="5">
      </ngx-material-timepicker>
    </div>
  </div>
  
  <div class="time-picker-group">
    <p class="time-label">Hasta</p>
    <div class="time-input">
      <mat-icon class="time-icon">schedule</mat-icon>
      <input [ngxTimepicker]="pickerEnd" 
        readonly 
        [format]="24" 
        [value]="endTime()">
      <ngx-material-timepicker #pickerEnd
        (timeSet)="onEndTimeChange($event)"
        [minutesGap]="5">
    </ngx-material-timepicker>
  </div>
</div>
