<table>
  <thead class="calendar-header">
    <tr>
      <th>Lun</th>
      <th>Mar</th>
      <th>Mie</th>
      <th>Jue</th>
      <th>Vie</th>
      <th>Sab</th>
      <th>Dom</th>
    </tr>
  </thead>

  <tbody>
    @for (week of shownCalendar(); track week.order) {
      <tr>
        @for (day of week.days; track day.date) {
          <td>
            <app-day-container
              class="inner-content" 
              [date]="day.date" 
              [events]="day.events" 
              [isOffMonth]="day.isOffMonth"
              [statusDay]="getStatusDay(day.date)">
            </app-day-container>
          </td>
        }
      </tr>
    }
  </tbody>
  @if (loadingEvents()) {
    <div class="loading-layout" [class.show-spinner]="showSpinner()">
      <img class="spinner-img" src="/assets/white_spinner.svg" alt="loading">
    </div>
  }
</table>