<div class="day-container" aria-disabled="true"
  (click)="onDayClick()"
  (keypress)="onDayClick()"
  [class.off-month]="isOffMonth()"
  [class.past-day]="statusDay() === 'past'"
  [class.present-day]="statusDay() === 'present'"
  [class.weekend-day]="isWeekend()">

  <div class="date-number">
    <span>{{date().getDate()}}</span>
  </div>

  <div class="event-chips-container">
    @for (event of getValidEvents(); track event.id) {
      @if (event.bands && event.bands.length > 0) {
        <app-event-chip [event]="event"></app-event-chip>
      }
    }
    @if (events().length >= 3) {
      <div class="gradient">
      </div>
    }
  </div>
  @if (events().length >= 3) {
    <div class="see-more">
      <img class="icon" src="/assets/more_info.svg" alt="see more events">
    </div>
  }
</div>
