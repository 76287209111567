import { Injectable } from '@angular/core';
import { ConcertEvent } from '../models/types.model';

@Injectable({
  providedIn: 'root'
})
export class FormatService {

  formatEventTitle (event: ConcertEvent): string {
    return event.festival || event.bands.join(' + ');
  }

  formatEventDate (date : string | undefined) {
    if (date) {
      const [year, month, day] = date.split('-');
      return `${day}-${month}-${year}`;
    }
    return '-';
  }
}
