<form>
  <mat-form-field class="example-chip-list" floatLabel="always">
    <!-- <mat-label>Filtra por provincias</mat-label> -->
    <mat-chip-grid #chipGrid aria-label="province selection">
      @for (province of provinces(); track $index) {
        <mat-chip-row (removed)="remove(province)">
          {{province}}
          <button matChipRemove [attr.aria-label]="'remove ' + province">
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip-row>
      }
    </mat-chip-grid>
    <input
      id="provinceInput"
      name="currentProvince"
      placeholder="Elige una o varias provincias"
      #provinceInput
      [(ngModel)]="currentProvince"
      [matChipInputFor]="chipGrid"
      [matAutocomplete]="auto"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"/>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
      @for (province of filteredprovinces(); track province) {
        <mat-option [value]="province">{{province}}</mat-option>
      }
    </mat-autocomplete>
  </mat-form-field>
</form>
