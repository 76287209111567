<div class="toggles-column">
  
  <div class="toggle-filter">
    <p><strong>Solo gratis</strong></p>
    <div class="checkbox-item">
      <label class="checkbox-wrap">
        <input type="checkbox"
          name="onlyFree" 
          class="checkbox-inp" 
          (change)="onTogglesChange($event)"
          [checked]="toggles().onlyFree">
        <span class="checkbox-mark"></span>
      </label>
    </div>
  </div>
  
  <div class="toggle-filter">
    <p><strong>Solo festivales</strong></p>
    <div class="checkbox-item">
      <label class="checkbox-wrap">
        <input type="checkbox" 
          name="onlyFest" 
          class="checkbox-inp" 
          (change)="onTogglesChange($event)"
          [checked]="toggles().onlyFest">

        <span class="checkbox-mark"></span>
      </label>
    </div>
  </div>
  
  <div class="toggle-filter">
    <p><strong>No mostrar festivales</strong></p>
    <div class="checkbox-item">
      <label class="checkbox-wrap">
        <input type="checkbox" 
          name="hideFest" 
          class="checkbox-inp" 
          (change)="onTogglesChange($event)"
          [checked]="toggles().hideFest">

        <span class="checkbox-mark"></span>
      </label>
    </div>
  </div>

</div>