<div class="main-body">

  <div class="tools-layout" [class.show]=mustShowToolsMobile()>
    <div class="overlay" 
      (click)="showFiltersMobile()"
      (keydown)="showFiltersMobile()"
      aria-disabled="true"></div>
    <div class="drawer">
      <app-tools-list (closeEvent)="showFiltersMobile()"></app-tools-list>
    </div>
  </div>

  <div class="main-layout">

    <div class="calendar-container">
      <div class="selector-header">
        <button class="filter-button" (click)="showFiltersMobile()">
          <img src="/assets/filter.svg" alt="filters">
        </button>
        <app-month-selector class="month-selector"
          [currentDate]="{year: currentDate().getFullYear(), month: currentDate().getMonth()}"
          (changeMonth)="onChangeMonth($event)">
        </app-month-selector>
      </div>
      <app-calendar-table [shownCalendar]="shownCalendar()"
        [loadingEvents]="loadingEvents()"
        [showSpinner]="showSpinner()"></app-calendar-table>
    </div>

    <app-extended-info (changeMonth)="onChangeMonth($event)"></app-extended-info>
  </div>

</div>