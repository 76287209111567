import { Component, signal, OnInit, output } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { type Day, type ConcertEvent } from '../../core/models/types.model';
import { selectorDay } from '../../core/states/day/day.selector';
import { Store } from '@ngrx/store';
import { AppState } from '../../core/states/app.state';
import { AsyncPipe, DatePipe } from '@angular/common';
import { EventInfoComponent } from './event-info/event-info.component';
import { EventsService } from '../../core/services/events.service';
import { EventDetailsModalComponent } from "./event-details-modal/event-details-modal.component";
import { MatDialog, MatDialogModule, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { addDay } from '@formkit/tempo';
import { launchExtendedInfo } from '../../core/states/day/day.actions';
import { OrderDropdownComponent } from "./order-dropdown/order-dropdown.component";
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-extended-info',
  standalone: true,
  imports: [AsyncPipe, DatePipe, EventInfoComponent, EventDetailsModalComponent, MatDialogModule, MatIcon, OrderDropdownComponent],
  providers: [
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
  ],
  templateUrl: './extended-info.component.html',
  styleUrl: './extended-info.component.scss'
})
export class ExtendedInfoComponent implements OnInit {
  // Observables
  selectedDayObservable$: Observable<Day | null>;
  eventRequest?: Subscription;

  // Outputs
  changeMonth = output<Date>();

  // Local Variables
  isOpen = signal(false);
  selectedOrder = signal('default');
  selectedDay = signal<Day>({
    date: new Date(),
    events: []
  });
  currentMonth = signal(1);
  shownEvents = signal<ConcertEvent[]>([]);
  filteredCalendar = signal<{ order: number, days: Day[] }[]>([]);

  constructor (private store: Store<AppState>, 
    private eventsService: EventsService, 
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute) {
    this.selectedDayObservable$ = this.store.select(selectorDay);
  }
  
  ngOnInit (): void {
    this.selectedDayObservable$.subscribe((selectedDay) => {
      if (selectedDay) {
        this.selectedDay.set(selectedDay);
        this.currentMonth.set(selectedDay.date.getMonth());
        this.shownEvents.set(this.getEventsForSelectedDate());
        this.sortEvents();
        this.isOpen.set(true);
      }
    });
    this.eventsService.filteredCalendar$.subscribe((filteredCalendar) => {
      this.filteredCalendar.set(filteredCalendar);
      this.shownEvents.set(this.getEventsForSelectedDate());
      this.sortEvents();
    });

    this.route.paramMap.subscribe(params => {
      const eventId = params.get('id');
      if (eventId) {
        this.openEventFromRoute(eventId);
      }
    });
  }

  openEventFromRoute (eventId: string) {
    const eventData = this.shownEvents().find(event => event.id === eventId);
  
    if (!eventData) {
      this.eventRequest = this.eventsService.getEventById(eventId)
        .subscribe({
          next: (response) => {
            this.openEventModal(response);
          },
          error: (error) => {
            console.error('Error handled in subscribe:', error.message);
          }
        });
    } else {
      this.openEventModal(eventData);
    }
  }
  
  openEventModal (eventData: ConcertEvent) {
    this.dialog.open(EventDetailsModalComponent, {
      data: { 
        event: eventData,
        date: this.toLocalDateString(this.selectedDay().date),
      }
    });
  }
  
  onEventInfoClick (eventData: ConcertEvent) {
    this.router.navigate([`/event/${eventData.id}`]);
  }
  
  private getEventsForSelectedDate (): ConcertEvent[] {
    if (this.selectedDay) {
      const dateKey = this.toLocalDateString(this.selectedDay().date);
      for (const week of this.filteredCalendar()) {
        for (const day of week.days) {
          if (this.toLocalDateString(day.date) === dateKey) {
            return day.events;
          }
        }
      }
    }
    return [];
  }

  private toLocalDateString (date: Date): string {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }

  onOrderChange (newOrder: string): void {
    this.selectedOrder.set(newOrder);
    this.sortEvents();
  }

  sortEvents (): void {
    const eventsCopy = [...this.shownEvents()];
    switch (this.selectedOrder()) {
    case 'alphabetic':
      eventsCopy.sort((a, b) => {
        const nameA = this.getEventName(a);
        const nameB = this.getEventName(b);
        return nameA.localeCompare(nameB);
      });
      break;

    case 'price':
      eventsCopy.sort((a, b) => (a.price || 999999) - (b.price || 999999));
      break;

    case 'start_time':
      eventsCopy.sort((a, b) => {
        const timeA = this.convertTimeToSortableValue(a.time);
        const timeB = this.convertTimeToSortableValue(b.time);
        return timeA - timeB;
      });
      break;

    default:
      eventsCopy.sort((a, b) => (b.relevance || 0) - (a.relevance || 0));
      break;
    }
    this.shownEvents.set(eventsCopy);
  }

  getEventName (event : ConcertEvent) : string {
    return event.festival || event.bands[0];
  }

  convertTimeToSortableValue (time: string): number {
    const [hours, minutes] = time.split(':').map(Number);
    let totalMinutes = hours * 60 + minutes;
  
    if (hours >= 0 && hours < 6) {
      totalMinutes += 24 * 60;
    }
  
    return totalMinutes;
  }

  openExtendedInfo (): void {
    this.isOpen.set(true);
  }

  hideExtendedInfo (): void {
    this.isOpen.set(false);
  }

  previousDay (): void {
    const newDate = addDay(this.selectedDay().date, -1);
    this.launchNewDay(newDate);
  }

  nextDay (): void {
    const newDate = addDay(this.selectedDay().date, 1);
    this.launchNewDay(newDate);
  }

  launchNewDay (newDate: Date): void {
    if (newDate.getMonth() !== this.currentMonth()) {
      this.currentMonth.set(newDate.getMonth());
      this.emitNewMonth(newDate);
    }
    const newDay = {
      date: newDate,
      events: this.selectedDay().events,
      isOffMonth: this.selectedDay().isOffMonth,      
    };

    this.store.dispatch(launchExtendedInfo({
      day: newDay,
    }));
  }

  emitNewMonth (newDate: Date) {
    const sendingDate : Date = new Date ();
    sendingDate.setMonth(newDate.getMonth());
    sendingDate.setFullYear(newDate.getFullYear());
    sendingDate.setDate(1);
    this.changeMonth.emit(sendingDate);
  }
}
