import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, throwError } from 'rxjs';
import { type ConcertEvent, type Day, type EventsResponse } from '../models/types.model';
import { type Filter } from '../models/filters.model';
import cloneDeep from 'lodash/cloneDeep';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { StorageService } from './storage.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  // constructor (private http: HttpClient) {}
  private filtersSubject = new BehaviorSubject<Filter>({ provinces: [], genres: [], times: [], toggles: {
    onlyFest: false,
    onlyFree: false,
    hideFest: false,
  } });
  private calendarSubject = new BehaviorSubject<{ order: number, days: Day[] }[]>([]);
  private filteredCalendarSubject = new BehaviorSubject<{ order: number, days: Day[] }[]>([]);
  private endpoint = environment.apiEndpoint;
  constructor (private httpClient: HttpClient, private toastr: ToastrService, private storageService: StorageService) {}

  filters$ = this.filtersSubject.asObservable();
  calendar$ = this.calendarSubject.asObservable();
  filteredCalendar$ = this.filteredCalendarSubject.asObservable();

  setFilters (filters: Filter) {
    this.filtersSubject.next(filters);
    this.applyFilters();
    this.storageService.setItem('filters', JSON.stringify(filters))
    this.toastr.success('Filtros aplicados', '', {
      timeOut: 1500,
      progressBar: false,
    });
  }

  setCalendar (calendar: { order: number, days: Day[] }[]) {
    this.calendarSubject.next(calendar);
    this.applyFilters();
  }

  getEventsForMonths (startDate: Date, endDate: Date): Observable<EventsResponse> {
    const params = new HttpParams()
      .set('start', this.formatDate(startDate))
      .set('end', this.formatDate(endDate));

    const apiUrl = this.endpoint + 'monthEvents';

    return this.httpClient.get<EventsResponse>(apiUrl, { params }).pipe(
      catchError((error) => {
        console.error('Error fetching events:', error);
        return throwError(() => new Error('Failed to fetch events.'));
      })
    );
  };

  getEventsFromArtist (artist: string, startDate: Date): Observable<EventsResponse> {
    const params = new HttpParams()
      .set('start', this.formatDate(startDate))
      .set('artist', artist);

    const apiUrl = this.endpoint + 'artist';

    return this.httpClient.get<EventsResponse>(apiUrl, { params }).pipe(
      catchError((error) => {
        console.error('Error fetching events:', error);
        return throwError(() => new Error('Failed to fetch events.'));
      })
    );
  }


  getEventById (eventId: string): Observable<ConcertEvent> {
    const params = new HttpParams()
      .set('id', eventId)

    const apiUrl = this.endpoint + 'event';

    return this.httpClient.get<ConcertEvent>(apiUrl, { params }).pipe(
      catchError((error) => {
        console.error('Error fetching events:', error);
        return throwError(() => new Error('Failed to fetch events.'));
      })
    );
  }

  formatDate (date: Date): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  private applyFilters () {
    const filters = this.filtersSubject.getValue();
    const filteredCalendar = cloneDeep(this.calendarSubject.getValue());

    const hasProvinceFilter = filters.provinces.length > 0 && !filters.provinces.includes('Todas');
    const hasGenresFilter = filters.genres.length > 0 && !filters.genres.includes('Todos');
    const hasFreeFilter = filters.toggles.onlyFree;
    const hasOnlyFestivalFilter = filters.toggles.onlyFest;
    const hasHideFestivalFilter = filters.toggles.hideFest;
    const hasTimeFilter = filters.times.length === 2 && (filters.times[0] !== '06:00' || filters.times[1] !== '05:55');
    
    const hasAnyFilter = hasTimeFilter || hasProvinceFilter || hasGenresFilter || hasFreeFilter || hasOnlyFestivalFilter || hasHideFestivalFilter;
  
    const shouldIncludeEvent = (event: ConcertEvent): boolean => {
      if (hasProvinceFilter && !this.compareProvincesName(filters.provinces, event.province)) {
        return false;
      }
      if (hasGenresFilter && !filters.genres.some((genre) => event.genres.includes(genre))) {
        return false;
      }
      if (hasFreeFilter && event.price !== 0) {
        return false;
      }
      if (hasOnlyFestivalFilter && !event.festival) {
        return false;
      }
      if (hasHideFestivalFilter && event.festival) {
        return false;
      }
      if (hasTimeFilter) {
        const startTime = this.getTimeValue(filters.times[0]);
        const endTime = this.getTimeValue(filters.times[1]);
        const eventTime = this.getTimeValue(event.time);

        if (endTime && startTime && eventTime) {
          if (endTime >= startTime) {
            return eventTime >= startTime && eventTime <= endTime;
          } else {
            return !(eventTime < startTime && eventTime > endTime);
          }
        }

      }
      return true;
    };
  
    if (hasAnyFilter) {
      filteredCalendar.forEach((week) => {
        week.days.forEach((day) => {
          day.events = day.events.filter(shouldIncludeEvent);
        });
      });
    }
    this.filteredCalendarSubject.next(filteredCalendar);
  }

  getTimeValue (time: string): number | undefined {
    if (!time) {
      return;
    }
    const [hours, minutes] = time.split(':').map(Number);
    
    if (hours < 6) {
      return 24 * 60 + hours * 60 + minutes;
    }
    return hours * 60 + minutes;
  }

  compareProvincesName (filteredProvinces: string[], eventProvince: string): boolean {
    if (!eventProvince) {
      return false;
    }
  
    const normalizeString = (str: string): string => {
      const normalized = str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  
      switch (normalized) {
      case 'Bilbao':
        return 'Vizcaya';
      case 'San Sebastian':
      case 'Donosti':
        return 'Gipuzcoa';
      case 'Vitoria':
        return 'Alava';
      case 'Barcelona':
        return 'Barcelona';
      case 'Girona':
      case 'Gerona':
        return 'Girona';
      case 'Lleida':
      case 'Lerida':
        return 'Lleida';
      case 'Tarragona':
        return 'Tarragona';
      default:
        return normalized;
      }
    };
  
    return filteredProvinces.some((province) =>
      normalizeString(province).toLowerCase() === normalizeString(eventProvince).toLowerCase());
  }
  
}
