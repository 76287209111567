<div class="tools-column">
  <div class="header">
    <button mat-icon-button class="close-modal-btn" (click)="closeMobileFilters()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="filters-block">
    <app-city-filter [initialValue]="storageFilters().provinces" (changeCityFilter)="onProvincesChanged($event)"></app-city-filter>
  </div>
  <div class="filters-block">
    <app-genre-filter [initialValue]="storageFilters().genres" (changeGenreFilter)="onGenresChanged($event)"></app-genre-filter>
  </div>
  <div class="filters-block">
    <app-toggles [initialValue]="storageFilters().toggles" (changeToggleOutput)="onToggleChanged($event)"></app-toggles>
  </div>
  <div class="filters-block">
    <app-time-filter [initialValue]="storageFilters().times" (changeTimeFilterOutput)="onTimeFilterChanged($event)"></app-time-filter>
  </div>
  <!-- @if (filtersApplied) {
    <div class="filters-block reset-filters">
      <button mat-button (click)="resetAllFilters()">
        <mat-icon class="icon">close</mat-icon>
        Restablecer filtros
      </button>
    </div>
  } -->
  <div class="apply-filters">
    <button mat-button (click)="closeMobileFilters()">
      <mat-icon class="icon">check</mat-icon>
      Aplicar filtros
    </button>
  </div>
</div>