import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID, signal } from '@angular/core';
import { DayContainerComponent } from './calendar-table/day-container/day-container.component';
import { type Day, type ConcertEvent } from '../core/models/types.model';
import { EventsService } from '../core/services/events.service';
import { MonthSelectorComponent } from './month-selector/month-selector.component';
import { ExtendedInfoComponent } from "./extended-info/extended-info.component";
import { ToolsListComponent } from "./tools-list/tools-list.component";
import { isPlatformBrowser } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { CalendarTableComponent } from "./calendar-table/calendar-table.component";

@Component({
  selector: 'app-main-body',
  standalone: true,
  imports: [DayContainerComponent, MonthSelectorComponent, ExtendedInfoComponent, ToolsListComponent, CalendarTableComponent],
  templateUrl: './main-body.component.html',
  styleUrl: './main-body.component.scss'
})

export class MainBodyComponent implements OnInit, OnDestroy {
  // Observables
  eventsRequest?: Subscription;

  // Local Variables
  emptyCalendar = signal<{ order: number, days: Day[] }[]>([]);
  shownCalendar = signal<{ order: number, days: Day[] }[]>([]);
  datesWithEvents = signal<Record<string, ConcertEvent[]>>({});
  currentDate = signal(new Date()); // Initialized on Today;
  mustShowToolsMobile = signal(false);
  loadingEvents = signal(false);
  showSpinner = signal(false);
  startX = 0;
  endX = 0;

  // Timeouts
  changeMonthTimeout?: ReturnType<typeof setTimeout>;
  showLoadingSpinnerTimeout?: ReturnType<typeof setTimeout>;

  private isBrowser: boolean;

  constructor (private eventsService: EventsService, private toastr: ToastrService,
   @Inject(PLATFORM_ID) platformId: object) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit (): void {
    this.eventsService.filteredCalendar$.subscribe((filteredCalendar) => {
      if (filteredCalendar.length > 0) {
        this.shownCalendar.set(filteredCalendar);
      }
    });
    this.generateCalendar();
    this.setupTouchEvents();
  }

  generateCalendar (userNewDate: Date | void): void {
    if (userNewDate) {
      this.currentDate.set(userNewDate);
    }
    const currentMonth = this.currentDate().getMonth();
    const currentYear = this.currentDate().getFullYear();

    const firstDayOfMonth = new Date(currentYear, currentMonth, 1);

    let dayOfWeek = firstDayOfMonth.getDay();
    dayOfWeek = (dayOfWeek === 0) ? 6 : dayOfWeek - 1;

    const startDate = new Date(firstDayOfMonth);
    startDate.setDate(startDate.getDate() - dayOfWeek);

    const weeks: { order: number, days: Day[] }[] = [];

    for (let i = 0; i < 5; i++) {
      const weekDays: Day[] = [];

      for (let j = 0; j < 7; j++) {
        weekDays.push({
          date: new Date(startDate.getTime()),
          isOffMonth: startDate.getMonth() !== currentMonth,
          events: [],
        });
        startDate.setDate(startDate.getDate() + 1);
      }
      weeks.push({
        order: i,
        days: weekDays,
      });
    }
    this.emptyCalendar.set(weeks);
    this.shownCalendar.set(this.emptyCalendar());
    this.fetchEvents();
  }

  fetchEvents (): void {
    const startDate = this.emptyCalendar()[0].days[0].date;
    const endDate = this.emptyCalendar()[4].days[6].date;

    if (this.eventsRequest) {
      this.eventsRequest.unsubscribe();
    }

    if (this.showLoadingSpinnerTimeout) {
      clearTimeout(this.showLoadingSpinnerTimeout);
    }

    this.showLoadingSpinnerTimeout = setTimeout(() => {
      this.showSpinner.set(true);
    }, 50);

    this.loadingEvents.set(true);

    this.eventsRequest = this.eventsService.getEventsForMonths(startDate, endDate)
      .subscribe({
        next: (response) => {
          this.stopLoading();
          this.datesWithEvents.set(response.events);
          this.assignEventsToDays();
          this.eventsService.setCalendar(this.emptyCalendar());
        },
        error: (error) => {
          this.stopLoading();
          this.shownCalendar.set(this.emptyCalendar()); // Show empty calendar
          this.showError()
          console.error('Error handled in subscribe:', error.message);
        }
      });
  }

  stopLoading () : void {
    if (this.showLoadingSpinnerTimeout) {
      clearTimeout(this.showLoadingSpinnerTimeout);
    }
    this.showSpinner.set(false);
    this.loadingEvents.set(false);
  }

  showError (): void {
    if (this.isBrowser) {
      setTimeout(() => {
        this.toastr.error('Lo sentimos! Por favor vuelve a intentarlo en unos momentos', 'Error cargando los eventos', {
          timeOut: 8000,
          progressBar: true,
        });
      }, 500)
    }
  }

  assignEventsToDays (): void {
    for (const week of this.emptyCalendar()) {
      for (const day of week.days) {
        const dateKey: string = this.toLocalDateString(day.date);
        day.events = this.datesWithEvents()[dateKey] || [];
      }
    }
  }

  toLocalDateString (date: Date): string {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // Los meses son 0-indexados
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }

  onChangeMonth (newDate: Date): void {
    if (this.isBrowser) {
      if (this.changeMonthTimeout) {
        clearTimeout(this.changeMonthTimeout);
      }
      this.changeMonthTimeout = setTimeout(() => { 
        this.generateCalendar(newDate);
      }, 100);
    }
  }

  showFiltersMobile () {
    this.mustShowToolsMobile.update((mustShow) => !mustShow);
  }

  private setupTouchEvents (): void {
    if (this.isBrowser) {
      const calendarElement = document.querySelector('.calendar-container');
  
      if (calendarElement) {
        calendarElement.addEventListener('touchstart', (event) => {
          this.onTouchStart(event as TouchEvent);
        });
  
        calendarElement.addEventListener('touchend', (event) => {
          this.onTouchEnd(event as TouchEvent);
        });
      }
    }
  }

  private onTouchStart (event: TouchEvent): void {
    this.startX = event.touches[0].clientX;
  }

  private onTouchEnd (event: TouchEvent): void {
    this.endX = event.changedTouches[0].clientX;
    this.handleSwipe();
  }

  private handleSwipe (): void {
    const swipeThreshold = 50; // Umbral de distancia en píxeles para considerar un deslizamiento

    if (this.startX - this.endX > swipeThreshold) {
      this.changeMonthFromSwipe('next');
    } else if (this.endX - this.startX > swipeThreshold) {
      this.changeMonthFromSwipe('previous');
    }
  }

  private changeMonthFromSwipe (direction: 'next' | 'previous'): void {
    const currentMonth = this.currentDate().getMonth();
    if (direction === 'next') {
      this.currentDate().setMonth(currentMonth + 1);
    } else if (direction === 'previous') {
      this.currentDate().setMonth(currentMonth - 1);
    }
    this.generateCalendar(this.currentDate());
  }

  ngOnDestroy (): void {
    if (this.changeMonthTimeout) {
      clearTimeout(this.changeMonthTimeout);
    }
    if (this.eventsRequest) {
      this.eventsRequest.unsubscribe();
    }
    if (this.showLoadingSpinnerTimeout) {
      clearTimeout(this.showLoadingSpinnerTimeout);
    }
  }
}
