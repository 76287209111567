import { Component, output, signal } from '@angular/core';
import { CityFilterComponent } from "./city-filter/city-filter.component";
import { type Filter , type Toggles} from '../../core/models/filters.model';
import { EventsService } from '../../core/services/events.service';
import { GenreFilterComponent } from './genre-filter/genre-filter.component';
import { TogglesComponent } from './toggles/toggles.component';
import { MatIcon } from '@angular/material/icon';
import { TimeFilterComponent } from "./time-filter/time-filter.component";
import { StorageService } from '../../core/services/storage.service';

@Component({
  selector: 'app-tools-list',
  standalone: true,
  imports: [CityFilterComponent, GenreFilterComponent, TogglesComponent, MatIcon, TimeFilterComponent],
  templateUrl: './tools-list.component.html',
  styleUrl: './tools-list.component.scss'
})
export class ToolsListComponent {
  // Outputs
  closeEvent = output<void>();

  // Local Variables
  selectedProvinces = signal<string[]>([]);
  selectedGenres = signal<string[]>([]);
  selectedTimes = signal<string[]>([]);
  toggles = signal<Toggles>({
    onlyFest: false,
    onlyFree: false,
    hideFest: false,
  });
  filtersApplied = signal(false);
  storageFilters = signal<Filter>({
    provinces: [],
    genres: [],
    toggles: {
      onlyFest: false,
      onlyFree: false,
      hideFest: false,
    },
    times: [],
  });

  constructor (private storageService: StorageService, private eventsService: EventsService) {
    const filtersJson = this.storageService.getItem('filters');
    this.setFiltersFromStorage(filtersJson);
  }

  setFiltersFromStorage (filtersJson: string | null): void {
    if (filtersJson) {
      try {
        this.storageFilters.set(JSON.parse(filtersJson));
        const { provinces, genres, toggles, times } = this.storageFilters();
        this.selectedProvinces.set(provinces);
        this.selectedGenres.set(genres);
        this.toggles.set(toggles);
        this.selectedTimes.set(times);
        this.emitFilters(this.storageFilters());
      } catch (error) {
        console.error('Error parsing filters:', error);
      }
    }
  }

  onProvincesChanged (selectedProvinces: string[]): void {
    this.selectedProvinces.set(selectedProvinces);
    this.emitFilters();
  }

  onGenresChanged (selectedGenres: string[]): void {
    this.selectedGenres.set(selectedGenres);
    this.emitFilters();
  }

  onToggleChanged (selectedToggles: Toggles): void {
    this.toggles.set(selectedToggles);
    this.emitFilters();
  }

  onTimeFilterChanged (selectedTimes: string[]): void {
    this.selectedTimes.set(selectedTimes);
    this.emitFilters();
  }

  closeMobileFilters ():void {
    this.closeEvent.emit();
  }

  resetAllFilters () : void {
    this.selectedProvinces.set([]);
    this.selectedGenres.set([]);
    this.toggles.set({
      onlyFest: false,
      onlyFree: false,
      hideFest: false,
    });
    this.selectedTimes.set([]);
    this.emitFilters();   
  }

  private emitFilters (storageFilters?: Filter): void {
    const allFilters: Filter = storageFilters || {
      provinces: this.selectedProvinces(),
      genres: this.selectedGenres(),
      toggles: this.toggles(),
      times: this.selectedTimes(),
    };
    
    // For Reset button
    // this.filtersApplied.set(
    //   allFilters.provinces.length > 0 ||
    //   allFilters.genres.length > 0 ||
    //   allFilters.times.length > 0 ||
    //   Object.values(allFilters.toggles).some(Boolean)
    // );
    this.eventsService.setFilters(allFilters);
  }
}
