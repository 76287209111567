
<div class="genres-layout">

  <div class="genre-list">
    @for (genre of allGenres(); track genre.id) {
      <div [class.active]="genre.active" 
        [class.inactive]="!genre.active"
        [class]="genre.name.toLowerCase()"
        (click)="toggleGenre(genre)"
        (keyup)="toggleGenre(genre)"
        aria-disabled="true">
        {{ genre.name }}
      </div>
    }
  </div>

</div>
<div class="genre-buttons">
  <button (click)="selectAll()">Marcar todos</button>
  <button (click)="deselectAll()">Desmarcar todos</button>
</div>