<div class="order-container">
  <div class="order-select-wrapper">
    <select #select (change)="onOrderChange(select.value)" class="order-select">
      <option default>Orden por defecto</option>
      @for (option of orderOptions(); track option.id) {
        <option [value]="option.name">{{ option.name }}</option>
      }
    </select>
    <mat-icon class="order-icon">swap_vert</mat-icon>
  </div>
</div>
