import { Component, input } from '@angular/core';
import { DayContainerComponent } from "./day-container/day-container.component";
import { Day } from '../../core/models/types.model';
import { sameDay } from "@formkit/tempo";

@Component({
  selector: 'app-calendar-table',
  standalone: true,
  imports: [DayContainerComponent],
  templateUrl: './calendar-table.component.html',
  styleUrl: './calendar-table.component.scss'
})
export class CalendarTableComponent {
  // Inputs
  showSpinner = input.required<boolean>();
  loadingEvents = input.required<boolean>();
  shownCalendar = input.required<{ order: number, days: Day[] }[]>();

  // Local variables
  todayDate = new Date();

  getStatusDay (date: Date): 'past' | 'present' | 'future' {
    if (sameDay(date, this.todayDate)) {
      return 'present';
    } else if (date < this.todayDate) {
      return 'past';
    } else {
      return 'future';
    }
  }
}
