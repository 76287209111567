import { createReducer, on } from '@ngrx/store';
import { launchExtendedInfo } from './day.actions';
import { type Day } from '../../models/types.model';

export interface DayState {
  selectedDay: Day | null;
}

export const initialState: DayState = {
  selectedDay: null,
};

export const dayReducer = createReducer(
  initialState,
  on(launchExtendedInfo, (state, { day }) => ({ ...state, selectedDay: day }))
);
