import {COMMA} from '@angular/cdk/keycodes';
import {ChangeDetectionStrategy, Component, computed, model, signal, output, input, OnInit} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatAutocompleteModule, MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatChipsModule} from '@angular/material/chips';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';

@Component({
  selector: 'app-city-filter',
  templateUrl: './city-filter.component.html',
  styleUrl: './city-filter.component.scss',
  standalone: true,
  imports: [MatFormFieldModule, MatChipsModule, MatIconModule, MatAutocompleteModule, FormsModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class CityFilterComponent implements OnInit {
  // Inputs
  initialValue = input<string[]>();

  // Outputs
  changeCityFilter = output<string[]>();

  // Materials Variables
  readonly separatorKeysCodes: number[] = [COMMA];
  readonly currentProvince = model('');
  readonly provinces = signal(['Todas']); // Inicializa como 'Todas'

  readonly allProvinces: string[] = ['Todas',
    'Alava', 'Albacete', 'Alicante', 'Almería', 'Asturias', 'Avila',
    'Badajoz', 'Barcelona', 'Burgos', 'Caceres', 'Cadiz', 'Cantabria',
    'Castellon', 'Ciudad Real', 'Cordoba', 'Cuenca', 'Girona', 'Granada',
    'Guadalajara', 'Guipuzcoa', 'Huelva', 'Huesca', 'Islas Baleares', 'Jaen',
    'La Coruña', 'La Rioja', 'Las Palmas', 'Leon', 'LLeida', 'Lugo', 'Madrid',
    'Malaga', 'Murcia', 'Navarra', 'Orense', 'Palencia', 'Pontevedra',
    'Salamanca', 'Segovia', 'Sevilla', 'Soria', 'Tarragona', 'Santa Cruz de Tenerife',
    'Teruel', 'Toledo', 'Valencia', 'Valladolid', 'Vizcaya', 'Zamora', 'Zaragoza'
  ];

  readonly filteredprovinces = computed(() => {
    const currentProvince = this.currentProvince().toLowerCase();
    return currentProvince
      ? this.allProvinces.filter((province) => province.toLowerCase().includes(currentProvince))
      : this.allProvinces.slice();
  });

  ngOnInit (): void {
    this.initialValueChange();
  }

  private initialValueChange () {
    const newValue = this.initialValue() || [];
    this.provinces.set(newValue.length ? newValue : ['Todas']);
  }

  selected (event: MatAutocompleteSelectedEvent): void {
    const value = event.option.viewValue;
    if (!this.provinces().includes(value)) {
      const currentProvinces = this.provinces();
      if (currentProvinces.length === 1 && currentProvinces[0] === 'Todas') {
        this.provinces.set([value]);
      } else {
        this.provinces.set([...currentProvinces, value]);
      }
    }
    const provinceInput = document.getElementById('provinceInput') as HTMLInputElement;
    if (provinceInput) {
      provinceInput.value = '';
    }
    this.currentProvince.set('');
    this.changeCityFilter.emit(this.provinces());
  }

  remove (province: string): void {
    const currentProvinces = this.provinces();
    const index = currentProvinces.indexOf(province);
    if (index >= 0) {
      currentProvinces.splice(index, 1);
      if (currentProvinces.length <= 0) {
        currentProvinces.push('Todas');
      }
      this.provinces.set([...currentProvinces]);
      this.changeCityFilter.emit(this.provinces());
    }
  }
}
