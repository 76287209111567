import { Component, input, output, ElementRef, HostListener, signal } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

interface SimpleDate {
  year: number,
  month: number,
};

@Component({
  selector: 'app-month-selector',
  standalone: true,
  imports: [MatIconModule],
  templateUrl: './month-selector.component.html',
  styleUrl: './month-selector.component.scss'
})

export class MonthSelectorComponent {
  // Inputs
  currentDate = input.required<SimpleDate>();

  // Outputs
  changeMonth = output<Date>();

  // Local Variables
  showMonthDropdown = signal(false);
  todayDate: SimpleDate = {
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  };
  monthsName: { name: string, ordinal: number, abr:string }[] = [
    { name: 'ENERO', ordinal: 0, abr: 'ene'},
    { name: 'FEBRERO', ordinal: 1, abr: 'feb' },
    { name: 'MARZO', ordinal: 2, abr: 'mar' },
    { name: 'ABRIL', ordinal: 3, abr: 'abr' },
    { name: 'MAYO', ordinal: 4, abr: 'may' },
    { name: 'JUNIO', ordinal: 5, abr: 'jun' },
    { name: 'JULIO', ordinal: 6, abr: 'jul' },
    { name: 'AGOSTO', ordinal: 7, abr: 'ago' },
    { name: 'SEPTIEMBRE', ordinal: 8, abr: 'sep' },
    { name: 'OCTUBRE', ordinal: 9, abr: 'oct' },
    { name: 'NOVIEMBRE', ordinal: 10, abr: 'nov' },
    { name: 'DICIEMBRE', ordinal: 11, abr: 'dic' }
  ];

  constructor (private eRef: ElementRef) {}

  @HostListener('document:click', ['$event'])
  clickOutside (event: MouseEvent) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.showMonthDropdown.set(false);
    }
  }

  previousMonth () :void {
    this.currentDate().month--;
    if (this.currentDate().month < 0) {
      this.currentDate().month = 11;
      this.currentDate().year--;
    }
    this.emitNewDate();
  }

  nextMonth () :void {
    this.currentDate().month++;
    if (this.currentDate().month > 11) {
      this.currentDate().month = 0;
      this.currentDate().year++;
    }
    this.emitNewDate();
  }

  toggleMonthDropdown () {
    this.showMonthDropdown.update((value) => !value);
  }

  selectMonth (monthIndex: number) {
    this.showMonthDropdown.set(false);
    this.currentDate().month = monthIndex;
    this.emitNewDate();
  }

  incrementYear () {
    this.currentDate().year++;
    this.emitNewDate();
  }

  decrementYear () {
    this.currentDate().year--;
    this.emitNewDate();
  }

  goToToday () {
    this.currentDate().month = this.todayDate.month;
    this.currentDate().year = this.todayDate.year;
    this.emitNewDate();
  }

  emitNewDate () {
    const newDate : Date = new Date ();
    newDate.setMonth(this.currentDate().month);
    newDate.setFullYear(this.currentDate().year);
    newDate.setDate(1);
    this.changeMonth.emit(newDate);
  }

  closeDropdown () {
    this.showMonthDropdown.set(false);
  }

}
