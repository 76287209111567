<div class="modal-container">
  <div class="modal-header">
    <h2>{{ getEventName() + ' - ' + data.event.location}}</h2>
    <button mat-icon-button class="close-btn" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="modal-content">
    <div class="event-content-row">
      <div class="event-info-column">
        @if (data.event) {
          <div class="event-info" data-info="time">
            <mat-icon>calendar_month</mat-icon>
            <div>{{ data.date | date: 'fullDate'}}</div>
          </div>
          @if (data.event.time && data.event.time !== '-') {
            <div class="event-info" data-info="time">
              <mat-icon>access_time</mat-icon>
              <div>{{ data.event.time }}</div>
            </div>
          }
          <div class="event-info" data-info="city">
            <mat-icon>location_city</mat-icon>
            <div>{{ data.event.city }}</div>
          </div>
          <div class="event-info" data-info="location">
            <mat-icon>location_on</mat-icon>
            <div>{{ data.event.location }}</div>
          </div>
          @if (data.event.price !== null && data.event.price !== undefined) {
            <div class="event-info" data-info="price">
              <mat-icon>attach_money</mat-icon>
              <div>{{ data.event.price + ' €' }}</div>
            </div>
          }
          @if (data.event.details && data.event.details !== '-') {
            <div class="event-info" id="description-info">
              <mat-icon>description</mat-icon>
              <div class="description">{{ data.event.details }}</div>
            </div>
          }
          <a id="link-info" [href]="data.event.link" target="_blank" class="ticket-link">
            <mat-icon>confirmation_number</mat-icon> Comprar entradas
          </a>

        }
      </div>
      <img class="poster-img" src="/assets/default_poster.jpg" alt="concert poster">
    </div>
    <div class="see-more-container">
      @if (!showingMoreEvents()) {
        <button class="see-more-btn" (click)="showMoreEvents()">
          <span>Ver más eventos del artista</span>
          <img class="icon" src="/assets/see_more_icon.svg" alt="see more events">
        </button>
      } @else {
        <button class="see-more-btn" (click)="hideMoreEvents()">
          <span>Ocultar eventos del artista</span>
          <img class="icon" src="/assets/see_less_icon.svg" alt="see less events">
        </button> 
      }
    </div>

    @if(showingMoreEvents()) {
      <div class="dropdown-container">

        @if(loadingMore()) {
          <div class="spinner-container">
            <img src="/assets/spinner.svg" alt="loading">
          </div>

        } @else {
          @if (moreEvents().length > 0) {
            <div class="more-events-container">
              @for (event of moreEvents(); track event.id) {
                <app-see-more-event class="event-row" [event]="event" (onEventClick)="changeEventModal($event)"></app-see-more-event>
              }
            </div>
          } @else {
            <span class="no-more-events"> Lo sentimos, no tenemos más conciertos de este artista</span>
          }
        }
      </div>
    }
  </div>
</div>
