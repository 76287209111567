import { Component, output, signal } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { OrderOption } from '../../../core/models/types.model';

@Component({
  selector: 'app-order-dropdown',
  standalone: true,
  imports: [MatIcon],
  templateUrl: './order-dropdown.component.html',
  styleUrl: './order-dropdown.component.scss'
})
export class OrderDropdownComponent {
  // Outputs
  orderChangeOutput = output<string>();
  
  // Local Variables
  showOrderDropdown = signal(false);
  orderOptions = signal<OrderOption[]>([
    { id: 1, name: 'Alfabético A-Z' },
    { id: 2, name: 'Precio' },
    { id: 3, name: 'Hora de inicio' }
  ]);
  orderMapping = signal<Record<string, string>>({
    'Alfabético A-Z': 'alphabetic',
    Precio: 'price',
    'Hora de inicio': 'start_time'
  });

  toggleOrderDropdown (): void {
    this.showOrderDropdown.set(!this.showOrderDropdown());
  }

  onOrderChange (selectedOrder: string): void {
    const mappedOrder = this.orderMapping()[selectedOrder] || 'default_order';
    this.orderChangeOutput.emit(mappedOrder);
  }
}
